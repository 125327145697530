<template>
<div class='b-app-header__wrapper h-pos-rel'>
    <div class='b-app-header__inner'>
        <div class='b-app-header__inner__logo'>
            <div
                v-if='!isDesktop()'
                class='b-app-header__burger'
                :class='{ "active": showMenuMob }'
                @click='toggleShowMenuMob'>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <router-link
                to='/'
                class='b-app-header__logo-wrapper
                b-app-header__aside-part'>
                <img :src='requireImage(`${mainLogo}.svg`)'>
            </router-link>
        </div>
        <div v-if='showMenuMob'
             class='b-app-header__mobile_menu_wrapper'>
            <FwMenu
                itemFontSize='16px'
                itemFontWeight='700'
                itemIconSize='15'
                :menuItems='menuItems'
                :activeId='activeId'
                :hideUnderline='true'>
            </FwMenu>
        </div>
        <div class='b-app-header__profile-wrapper
                    b-app-header__aside-part'>
            <router-link
                to='/profile'
                class='b-app-header__profile-inner'>
                <div>
                    <div class='b-app-header__profile-name h-font-14'>
                        <b>
                            {{ userData.first_name | capitalizeAll }}
                        </b>
                    </div>
                    <div class='b-app-header__profile-name h-font-12 h-opacity-0_5 h-uppercase'>
                        <b>
                            {{ userData.account_name }}
                        </b>
                    </div>
                </div>
                <div class='b-app-header__profile-image h-flex h-flex-center text-right'
                     :style='profileStyles'>
                    <FwIcon
                        v-if='!userData.avatar_url'
                        class='b-worker__avatar__icon'
                        icon='no-avatar'
                        size='12'
                        color='#fff'>
                    </FwIcon>
                </div>
            </router-link>
        </div>
    </div>
    <div v-if='$route.meta.progressValue'
         class='b-app-header__progress-bar h-pos-abs'
         :style='{ width: `${$route.meta.progressValue}%` }'></div>
</div>
</template>

<script lang='ts'>
import { Component, Watch, Mixins } from 'vue-property-decorator';
import i18n from '@/locale';
import { separateColor, hexToRgbA } from '@/helpers/colors';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

type MenuItemsType = {
    id: number,
    to: string,
    title: string,
    icon: string,
}

@Component
export default class AppHeader extends Mixins(PermissionsMixin, AccountMixin) {
    menuItems: Array<MenuItemsType> = [];
    showMenuMob: boolean = this.isTablet() || this.isDesktop();
    windowWidth: number | null = null;

    handleResize() {
        this.windowWidth = window.innerWidth;
        this.showMenuMob = this.windowWidth > 768;
    }

    async updateMenu() {
        const menuItems = [];
        if (this.currentUserRole && this.currentUserRole.appointment_rights && this.currentUserRole.appointment_rights.create) {
            menuItems.push({
                id: 1,
                to: '/appointment/matmut/fetch-details',
                title: i18n.tc('HEADER.PLAN'),
                icon: 'ok-circle',
            });
        }
        menuItems.push({
            id: 2,
            to: '/consult/choose',
            title: i18n.tc('HEADER.CONSULT'),
            icon: 'eye-alt',
        });
        if (this.currentUserRole && this.currentUserRole.setting_rights.read) {
            const data = await import('@/routes/settings/children-routes');
            const settingsChildrenRoutes = data.default(this.isMatmutAccount);
            // eslint-disable-next-line max-len
            const firstAvailableSettingUrlByPermission = settingsChildrenRoutes.find(item => item.meta && item.meta.text && this.currentUserRole && this.currentUserRole[item.meta.rightBlock] && this.currentUserRole[item.meta.rightBlock][item.meta.right]);
            if (firstAvailableSettingUrlByPermission) {
                menuItems.push({
                    id: 3,
                    to: firstAvailableSettingUrlByPermission.meta!.cleanPath,
                    title: i18n.tc('HEADER.SETTINGS'),
                    icon: 'settings',
                });
            }
        }
        this.menuItems = menuItems;
    }

    get userData() {
        return this.user();
    }

    get isProductionTraining() {
        return process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'training';
    }

    get profileStyles() {
        return {
            backgroundImage: `${this.avatarColor}`,
        };
    }

    get avatarColor() {
        let result;
        if (this.userData.color) {
            const colorArray = separateColor(this.userData.color);
            result = this.userData.avatar_url ?
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)},  ${hexToRgbA(colorArray[1], 0.5)}),
        url(${this.userData.avatar_url})` :
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 143, 1))`;
        }
        return result;
    }

    get activeId(): number {
        const item = this.menuItems.find(menu => {
            const matchedString: string | null | undefined = menu && menu.to ? menu.to.split('/').find((str: string) => str.length) : null;
            return matchedString ? this.$route.path.startsWith(matchedString) || this.$route.path.startsWith(`/${matchedString}`) : false;
        });
        return item ? item.id : 0;
    }

    toggleShowMenuMob(): void {
        this.showMenuMob = !this.showMenuMob;
    }

    async created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.updateMenu();
    }

    @Watch(`$i18n.locale`)
    $i18nHandler() {
        this.updateMenu();
    }

    @Watch(`$route`)
    showMenuMobHandler() {
        if (this.showMenuMob) {
            this.handleResize();
        }
        this.updateMenu();
    }
}
</script>

<style lang='sass'>
    .b-app-header
        &__progress-bar
            bottom: 0
            border-bottom: 3px solid $aqua-marine
            transition: width 0.4s ease-in-out

        &__burger
            display: none

        &__wrapper
            height: $header-height
            width: 100%
            background-color: #fff
            display: flex
            flex-direction: column
            justify-content: center

            @include media('<=phone')
                //z-index: 2
                box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06)

        &__inner
            height: 90px
            display: flex
            align-items: center
            justify-content: space-between

            &__logo
                display: flex
                align-items: center
                justify-content: center

        &__aside-part
            height: 100%
            display: flex
            align-items: center
            max-width: 270px
            width: 100%

        &__logo-wrapper
            padding-left: 100px
            img
                max-width: 170px

        &__profile-wrapper
            padding-right: 100px
            justify-content: flex-end

        &__profile-inner
            display: flex
            align-items: center
            cursor: pointer

            &:hover
                .b-app-header__profile-name
                    text-shadow: 0 3px 7px rgba(0, 0, 0, 0.12)

                .b-app-header__profile-image
                    box-shadow: $middle-box-shadow

            &.router-link-active
                .b-app-header__profile-name
                    font-weight: bold

        &__profile-name
            text-align: right
            font-size: 16px
            font-weight: 400
            margin-right: 20px
            transition: text-shadow 0.3s
            color: $alt-blue
            white-space: nowrap

            span
                font-weight: 400

        &__profile-image
            width: 40px
            height: 40px
            border-radius: 50%
            background-color: $gray
            background-size: cover
            background-position: center
            transition: box-shadow 0.3s

    @include media('<=tablet')
        .b-app-header
            &__burger
                display: flex
                position: relative
                z-index: 31
                width: 50px
                height: 20px
                border: 0
                background: none
                cursor: pointer
                div
                    margin-left: 20px
                    position: absolute
                    left: 0
                    background: #27dbbd
                    width: 30px
                    height: 3px
                    transition: transform .2s ease, top .2s ease .2s, bottom .2s ease .2s, opacity .2s ease .2s
                    &:nth-child(1)
                        top: 0

                    &:nth-child(2)
                        top: calc(50% - 2px)

                    &:nth-child(3)
                        bottom: 0

                &.active
                    div
                        transition: transform .2s ease .2s, top .2s ease, bottom .2s ease, opacity .2s ease
                        &:nth-child(1)
                            transform: rotate(45deg)
                            top: calc(50% - 2px)

                        &:nth-child(2)
                            opacity: 0

                        &:nth-child(3)
                            transform: rotate(-45deg)
                            bottom: calc(50% - 2px)

            &__logo-wrapper
                padding-left: 0
                margin-left: 25px
                img
                    max-width: 124px

            &__profile-wrapper
                padding-right: 20px

            &__mobile_menu_wrapper
                @include media('<=phone')
                    .fw-menu
                        box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.22)

                &:before
                    content: ''
                    position: fixed
                    left: 0
                    top: 0
                    background-color: rgba(0, 0, 0, 0.7)
                    width: 100%
                    height: 100%
                    z-index: 6

        .fw-menu
            flex-flow: column
            padding: 60px
            background: #fff
            width: 300px
            height: 100%
            position: fixed
            z-index: 10
            top: 0
            left: 0

            .fw-menu__item--underlined:hover
                color: #203f6a
</style>
