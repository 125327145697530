import { User } from '@/types/User';

function isImaSanteAccount(currentUser: User): boolean {
    return currentUser.account_name === 'ima_sante';
}

function isCalizyAccount(currentUser: User): boolean {
    return currentUser.account_name === 'calizy';
}

function isMgasAccount(currentUser: User): boolean {
    return currentUser.account_name === 'mgas';
}

function isImhAccount(currentUser: User): boolean {
    return currentUser.account_name === 'imh';
}

function isMatmutAccount(currentUser: User): boolean {
    return currentUser.account_name === 'matmut';
}

function isMmeAccount(currentUser: User): boolean {
    return currentUser.account_name === 'mme';
}

function isApizeeAccount(currentUser: User): boolean {
    return currentUser.account_name === 'apizee';
}

function isApgisAccount(currentUser: User): boolean {
    return currentUser.account_name === 'apgis';
}

function isMgefiAccount(currentUser: User): boolean {
    return currentUser.account_name === 'mgefi';
}

function isHesAccount(currentUser: User): boolean {
    return currentUser.account_name === 'hes';
}
function isAlmAccount(currentUser: User): boolean {
    return currentUser.account_name === 'alm';
}

function isPlanifAccount(currentUser: User): boolean {
    return currentUser.account_name === 'planifique';
}

export {
    isImaSanteAccount,
    isCalizyAccount,
    isMgasAccount,
    isImhAccount,
    isMatmutAccount,
    isMmeAccount,
    isApizeeAccount,
    isApgisAccount,
    isMgefiAccount,
    isHesAccount,
    isPlanifAccount,
    isAlmAccount,
};
