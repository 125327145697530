<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
<div class='fw-select-address h-width-100p qa-select-address'>
    <FwSpinLoader
        v-if='placeLoading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwSelect
        ref='autocomplete'
        v-model='value'
        v-focus='autofocus'
        class='fw-select-address fw-select-address__absolute b-select-address'
        :class='{
            "b-select-address--empty": !value && !startAddress,
            "b-select-address--with-start-address": startAddress && !value,
            "b-select-address--with-value": value,
            "b-select-address--search-query-short": isSearchQueryShort,
            "b-select-address--loading": loading,
        }'
        :multiple='false'
        label='text'
        :clear-on-select='true'
        :propsPlaceholder='text'
        type='white'
        :searchable='true'
        :allowEmpty='false'
        :searchIcon='true'
        :closeOnSelect='true'
        noElementsFoundText=''
        :disabled='isDisabled'
        :internalSearch='false'
        openDirection='bottom'
        :options='options'
        @select='getPlaceByApi'>
        <template v-slot:option='{ props }'>
            <FwSpinLoader
                v-if='loading'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='loading'
                className='h-p-20'>
            </FwSpinLoader>
            <slot
                :props='props'
                name='option'>
                <div :style='{ opacity: loading ? 0 : 1 }'
                     class='multiselect__content-item h-pr-10 qa-select-address-option'>
                    <div class='multiselect__item-header h-flex h-flex-dir-column h-mr-50 h-pl-10 h-max-width-100p'>
                        <span class='multiselect__title-first'>
                            {{ props.option.country }}
                        </span>
                        <span class='multiselect__title-second h-font-16'>
                            {{ props.option.city }}
                        </span>
                    </div>
                    <FwIcon
                        class='h-flex-center h-pointer'
                        icon='arrow-right'
                        size='14'
                        color='#27DBBD'>
                    </FwIcon>
                </div>
            </slot>
        </template>
        <template v-if='!isSearchQueryShort'
                  #noResult>
            <div class='b-select-address--no-result qa-select-address-no-result'>
                {{ $t('PLAN.SOCIETY.NO.ELEMENT.FOUND') }}
            </div>
        </template>
        <template #noOptions>
            <span></span>
        </template>
    </FwSelect>
    <div v-if='postCodeCannotDetect'
         class='h-flex h-flex-space-between h-mb-20 h-mt-20 qa-select-address-post-code'>
        <FwFormInput
            class='h-flex-1-0'
            labelType='label-medium'
            :title='`${$t(`LABEL.ZIP.CODE`)}*`'>
            <FwInput
                :value='post_code'
                type='number'
                name='text'
                :placeholder='$t(`LABEL.ZIP.CODE`)'
                :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                inputStyle='white-bigger'
                @update:value='value => changePostCodeValue(value)'>
            </FwInput>
        </FwFormInput>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Mixins, Prop, Emit, Watch } from 'vue-property-decorator';

import get from 'lodash.get';

import { SelectAddressOptionType } from '@/types/Appointment';
import { GoogleMapPlaceType, ParsedAddressData } from '@/types/GoogleMap';

import GoogleApi from '@/mixins/google-api';
import ValidationMixin from '@/mixins/validation';
import PermissionMixin from '@/mixins/permissions';

import { parseAutocompleteWithApiAsAddress } from '@/helpers/autocomplete';
import { Debounce } from '@/helpers/decorators/debounce';

import { AutocompleteWebApi } from '@/api/autocomplete/AutocompleteApi';
import { AddressFrResponseType, AddressResponseType } from '@/types/Autocomplete';
import { Country } from '@/types/Account';

const MIN_GOOGLE_AUTO_COMPLETE_REQUEST_LETTERS_LENGTH = 7;

type GoogleMapItemType = {
    description: string
    matched_substrings: Array<{ length: number, offset: number }>,
    place_id: string
    reference: string
    structured_formatting: {
        main_text: string
        main_text_matched_substrings: Array<{ length: number, offset: number }>
        secondary_text: string
    }
    terms: Array<{ value: string, offset: number }>
    types: Array<string>
}

@Component
export default class SelectAddress extends Mixins(GoogleApi, ValidationMixin, PermissionMixin) {
    @Prop({ type: String, default: null }) readonly startAddress!: string | null;
    @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;
    @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) readonly postCodeNotNeed!: boolean;
    @Prop({ type: Boolean, default: true }) readonly isCountrySelector!: boolean;
    @Prop({ type: Boolean, default: false }) readonly useDefaultCountryIso!: boolean;
    @Prop({ type: String, default: { name: 'France', country_iso: 'FRA' }}) readonly country!: Country;

    options: Array<SelectAddressOptionType> = [];
    value: string = '';
    loading: boolean = false;
    placeLoading: boolean = false;
    postCodeCannotDetect: boolean = false;
    post_code: string = '';
    $refs!: {
        autocomplete: Vue
        map: HTMLElement
    };

    get text() {
        if (this.startAddress) {
            return this.startAddress;
        }
        if (this.value && typeof this.value === `string`) {
            return this.value;
        }
        // @ts-ignore-next-line
        return this.value ? this.value.text : this.$t(`SELECT.ADDRESS.APPOINTMENT.PLACEHOLDER`);
    }

    get countryIso() {
        if (this.country && this.country.country_iso) {
            return this.country.country_iso;
        }

        return 'FRA';
    }

    get countryName() {
        if (this.country && this.country.name) {
            return this.country.name;
        }

        return 'France';
    }

    get isSearchQueryShort(): boolean {
        return !this.value || this.value.length < MIN_GOOGLE_AUTO_COMPLETE_REQUEST_LETTERS_LENGTH;
    }

    displaySuggestions(predictions: Array<GoogleMapItemType> | Array<AddressFrResponseType> | Array<AddressResponseType>) {
        if (!predictions || !predictions.length) {
            this.loading = false;
            return;
        }
        this.options = (predictions as Array<AddressFrResponseType> | Array<AddressResponseType>).map((item: AddressFrResponseType | AddressResponseType) => (item.label ? {
            country: item.label,
            text: item.label,
            ...item.id ? { id: item.id } : {},
            ...item,
        } : {}));
        // @ts-ignore-next-line
        this.loading = false;
    }
    async getPlaceByApi(place: SelectAddressOptionType | AddressFrResponseType | AddressResponseType) {
        await this.getPlaceByCountry(place as AddressFrResponseType | AddressResponseType);
    }

    async getPlaceByCountry(value: AddressFrResponseType | AddressResponseType) {
        let place = value;
        if (place) {
            if (!(place.latitude && place.longitude) && place.id) {
                place = await AutocompleteWebApi.fetchPlaceById({
                    id: place.id,
                });
            }
            if (!place.post_code) {
                this.postCodeCannotDetect = true;
                this.sentNotif(`LABEL.ADDRESS.POST.CODE`);
            } else {
                this.postCodeCannotDetect = false;
            }
            const parsedAddressData = parseAutocompleteWithApiAsAddress({ ...place, country: this.countryName as string });
            this.inputHandler(parsedAddressData);
        }
    }

    mountAddressSelector(): void {
        if (this.$refs.autocomplete && this.$refs.autocomplete.$children && this.$refs.autocomplete.$children[0] && this.$refs.autocomplete.$children[0].$refs.search) {
            const multiselect: HTMLInputElement = (this.$refs.autocomplete.$children[0].$refs.search as HTMLInputElement);
            const inputHandler = async() => {
                await this.$nextTick();
                this.value = multiselect.value;
                if (this.value !== '' && !this.isSearchQueryShort) {
                    this.doSearchDebounceImmediate(multiselect);
                } else {
                    this.options = [];
                }
            };
            multiselect.addEventListener('input', inputHandler);
            multiselect.addEventListener('paste', inputHandler);
        }
    }

    changePostCodeValue(value: string) {
        this.post_code = value;
        this.changePostcode(value);
    }

    changePostcode(code: string) {
        this.$emit('changePostCode', code);
    }

    @Debounce(600)
    async doSearchDebounceImmediate(multiselect: HTMLInputElement) {
        if (this.value) {
            const q = get(this.value, 'text', this.value);
            this.loading = true;
            let result;
            if (this.countryIso === 'FRA' && (this.isCountrySelector || this.useDefaultCountryIso)) {
                result = await AutocompleteWebApi.getFrAddresses({ q });
            } else {
                result = await AutocompleteWebApi.getAddresses({
                    q,
                    iso: this.countryIso as string,
                });
            }
            this.displaySuggestions(result);
            multiselect.focus();
        }
    }

    mounted() {
        this.mountAddressSelector();
    }

    @Emit('input')
    inputHandler(parsedAddressData: ParsedAddressData): ParsedAddressData {
        return parsedAddressData;
    }

    @Emit('sendPlace')
    sendPlaceHandler(place: GoogleMapPlaceType): GoogleMapPlaceType {
        return place;
    }

    @Watch('country')
    updateValue() {
        this.value = '';
        this.options = [];
    }
}
</script>

<style lang='sass'>
.pac-container
    display: none !important

.b-select-address
    .multiselect__input::placeholder
        width: 100%
    .multiselect__tags
        border-radius: 10px !important

    &.b-select-address--empty
        .multiselect__content-wrapper
            display: none !important

    .multiselect__content-wrapper
        min-width: 440px
        width: 100% !important

        @include media('<=phone')
            min-width: none !important

    .multiselect__title-first
        white-space: nowrap
        overflow: hidden
        width: 580px
        text-overflow: ellipsis

    &--with-start-address
        .multiselect__placeholder
            color: #203f6a !important

        .multiselect__option
            display: none !important

    &--search-query-short
        .multiselect__option
            display: none !important

    &--loading
        .multiselect__option
            display: none !important

    &--no-result
        background-color: #fff
        padding: 10px
        border-radius: 4px
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05)

.b-select-address .multiselect__content-wrapper
    @include media('<=phone')
        min-width: auto !important
</style>
