<template>
<div class='
    qa-manage-shifts-popup
    b-blue-background
    b-blue-background__opacity
    b-cancel-popup__select
    b-blue-background--fixed
    b-shifts__wrapper'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='600px'
        width='540px'
        :title='$t("SERVICES_POINT.SHIFT.TXT")'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :disableClosingOnClickOutside='true'
        :hideSubPopup='!showSubPopup'
        :padding='isMobile() ? "30px 20px" : "40px 50px 25px"'
        @close='$emit("close")'>
        <template #body>
            <div :style='isDesktop() ? "width: 360px" : "width: 300px"'
                 class='h-margin-0-auto'>
                <div class='b-shifts__wrapper__select qa-shift-advisor-select'>
                    <FwFormInput
                        labelType='label-medium'
                        class='h-flex-1-0'>
                        <div class='fw-select-base'>
                            <FwSelect
                                v-model='shiftsAdvisor'
                                class='fw-select-with-icon--big'
                                :propsPlaceholder='$t(`SERVICES_POINT.SHIFTS.FIND_ADVISOR`)'
                                type='white'
                                :clear-on-select='false'
                                :searchable='true'
                                :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                                label='full_name'
                                :internalSearch='false'
                                openDirection='bottom'
                                pagination
                                withCircles
                                canNotDelete
                                :options='shiftsAdvisors'
                                @search-change='searchChangeHandler'
                                @goToNextPage='getNextPage'>
                            </FwSelect>
                        </div>
                    </FwFormInput>
                </div>
                <div>
                    <FwDatepicker
                        v-model='localDate'
                        class='h-mv-20'
                        :class='{ "qa-advisor-reserved-slots-datepicker-slot": currentAvailabilitySlot }'
                        :propsRange='false'
                        :selectMode='false'
                        :propsLang='`${$i18n.locale}`'
                        pickerStyle='static'
                        :bigStyle='true'
                        :hideIcon='true'
                        :showWeeksNumbers='true'
                        :reservedSlots='slots'
                        :hideInput='true'
                        :defaultValue='lastEventDate'
                        :disabledDate='disabledDateByObject'
                        @change-calendar-month='changeCalendarMonth'
                        @selectDate='selectDateHandler'>
                    </FwDatepicker>
                </div>
            </div>
        </template>

        <template #subPopup>
            <template v-if='localDate && showSubPopup && !forceTempHidePopup'>
                <ChooseShiftsSlotForm
                    v-if='showChooseReservedSlot'
                    :date='localDate'
                    :currentSlots='currentSlots'
                    :currentInstancesData='currentInstancesData'
                    :isReservedSots='isReservedSotsPopup'
                    :recurrenceLoading='recurrenceLoading'
                    @closePopup='closePopup'
                    @chooseSlot='setSlotHandler'
                    @addNewSlot='setShowAddReservedSlot'>
                </ChooseShiftsSlotForm>
                <AddReservedSlotForm
                    v-else
                    :date='localDate'
                    :shiftsAdvisorId='shiftsAdvisor ? shiftsAdvisor.id: ``'
                    :reservedSlot='currentSlot'
                    :instanceData='selectedSlot'
                    :isRequestSendingStatus='isRequestSending'
                    :isReservedSots='isReservedSotsPopup'
                    :isShift='isShift'
                    type='shift'
                    @closePopup='closePopup'
                    @addSlot='createEvent'
                    @removeSlot='openDeletePopup'>
                </AddReservedSlotForm>
            </template>
        </template>
    </FwPopup>

    <DeleteShiftsPopup
        v-if='showReservedSlotPopup'
        :instanceData='instanceData'
        :isRequestSending='isRequestSending'
        type='reserved-slots'
        @click.native.stop
        @removeSlot='removeEvent'
        @close='closeReservedSlotPopup'>
    </DeleteShiftsPopup>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AddReservedSlotForm } from '@/components/forms/add/AddReservedSlotForm';
import { ChooseReservedSlotForm } from '@/components/forms/add/ChooseReservedSlotForm';
import { ReservedSlotsList } from '@/components/simple/ReservedSlotsList';
import ReservedSlotsMixin from '@/mixins/ReservedSlotsMixin';
import { CategorySkillNameType } from '@/types/Appointment';
import { ALL_ID } from '@/helpers/appointment';
import { DeleteShiftsPopup } from '@/components/popups/DeleteShiftsPopup';
import { ChooseShiftsSlotForm } from '@/components/forms/add/ChooseShiftsSlotForm';
import { EventsWebApi } from '@/api/events/EventsApi';
import { BaseMetaType } from '@/types/Response';
import {
    ServicePointAdvisorsResponseType,
    ServicePointShiftListType,
    ShiftAdvisorListType,
    ShiftResponseFullData, ShiftSlotsResponseType,
} from '@/types/Events';
import { capitalizeAll } from '@/helpers/string';

@Component({
    components: {
        ChooseShiftsSlotForm,
        DeleteShiftsPopup,
        AddReservedSlotForm,
        ChooseReservedSlotForm,
        ReservedSlotsList,
    },
})
export default class ShiftsPopup extends Mixins(ReservedSlotsMixin) {
    isShift: boolean = true;
    isReservedSotsPopup: boolean = false;
    isUnavailability: boolean = false;
    isAdvisorsLoading: boolean = false;
    recurrenceLoading: boolean = false;
    currentDate = new Date();
    shiftsAdvisors: Array<ShiftAdvisorListType> = [];
    shiftsAdvisor: ShiftAdvisorListType | null = null;
    meta: BaseMetaType | null = null;
    page: number = 1;
    searchStr: string = '';
    slots: Array<{ id: string, date: string}> = []

    updateSlotType(type: string) {
        this.startActiveId = type;
        if (!this.changedCalendarDate) return;
        this.getMonthData({ date: this.changedCalendarDate, isFullMonthRequest: true });
    }

    async selectDateHandler(date: Date) {
        const currentDot = this.slots.find(item => dayjs(item.date).isSame(dayjs(date), 'day'));
        if (!currentDot) {
            this.selectDate(date);
            return;
        }
        this.forceTempHidePopup = true;
        try {
            const data: ShiftSlotsResponseType = await EventsWebApi.getShiftsSlotsForDate({
                dt: date.toDateString(),
                sp_id: (this.$route.params.id as string),
                ...(this.shiftsAdvisor && { advisor_id: this.shiftsAdvisor.id }),
            });
            // @ts-ignore-next-line
            this.events = data.service_point_shifts || [];
            this.reservedSlots = (this.events as Array<any>).map(item => ({
                date: new Date(item.dt_start),
                id: item.id,
            }));
            this.selectDate(date);
        } catch (e) {
            this.forceTempHidePopup = false;
        } finally {
            this.isAdvisorsLoading = false;
        }
    }

    async setSlotHandler(instance: ServicePointShiftListType) {
        try {
            this.recurrenceLoading = true;
            const data: ShiftResponseFullData = await EventsWebApi.getFullShiftData(instance.id);
            // @ts-ignore
            this.setSlot(data);
        } catch (e) {
            console.log(e);
        } finally {
            this.recurrenceLoading = false;
        }
    }

    getNextPage() {
        if (this.isAdvisorsLoading || !this.meta?.next_page) return;
        this.page += 1;
        this.fetchShiftAdvisors();
    }

    searchChangeHandler(str: string) {
        if (str.length >= 3 || !str || str.length === 0) {
            this.searchStr = str;
            this.page = 1;
            this.fetchShiftAdvisors();
        }
    }

    get disabledDateByObject() {
        return this.events && {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate()) : new Date(),
            excludedSlots: this.slots ? this.slots.map(item => item.date) : [],
        };
    }

    async fetchShiftAdvisors() {
        if (this.isAdvisorsLoading) return;
        this.isAdvisorsLoading = true;
        try {
            const data: ServicePointAdvisorsResponseType = await EventsWebApi.getServicePointAdvisors({
                page: this.page,
                last_name: this.searchStr,
            });
            this.meta = data.meta;
            const preparedAdvisors = data.service_point_advisors.map(
                item => ({
                    ...item,
                    full_name: capitalizeAll(`${item.first_name || ''} ${item.last_name || ''}`) })
            );
            this.shiftsAdvisors = this.page === 1 ? preparedAdvisors : [...this.shiftsAdvisors, ...preparedAdvisors];
        } catch (e) {
            console.log(e);
        } finally {
            this.isAdvisorsLoading = false;
        }
    }

    @Watch('shiftsAdvisor')
    async shiftsAdvisorHandler(advisor: ShiftAdvisorListType | null) {
        await this.getMonthData({ date: this.changedCalendarDate, isFullMonthRequest: true });
        if (this.localDate) {
            this.events = [];
            this.reservedSlots = [];
            this.selectDateHandler(this.localDate);
        }
    }

    async created() {
        this.fetchShiftAdvisors();
    }
}
</script>

<style lang='sass'>
.b-shifts-marked-date
    width: 30px
    height: 30px
    background-color: rgba(255, 0, 0, 0.04)
    font-weight: 400

.b-shifts
    &__wrapper
        .multiselect__input::placeholder
            width: 200px !important

        .multiselect__content-wrapper
            max-height: 300px !important

        .mx-datepicker
            box-shadow: none !important

        .fw-popup__sub_popup
            width: 380px !important
            overflow: auto
            max-height: calc(100vh - 70px)
            transform: translate(80%, 0) !important
            overflow: visible !important

            @include media('<=phone')
                transform: none !important

        &__select
            z-index: 1005
            position: relative

            .fw-select, .multiselect__tags
                height: 40px !important
                max-height: 40px !important
                min-height: 40px !important

            .fw-select-white .multiselect__tags
                border: 1px solid rgba(33, 63, 107, 0.18) !important

        .fw-select-base .multiselect__input
            padding: 0 !important

        .mx-datepicker-reserve-slot-color--green .fw-datepicker-reserved
            background-color: #FFBD01
</style>
