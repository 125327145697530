<template>
<div class='b-intervention-code_main h-flex-center h-flex-dir-column'
     :class='{ "b-intervention-code_main--popup": popupStyle }'>
    <div class='b-intervention-code__inputs-wrapper'>
        <FwButton
            class='h-mh-10 qa-appointment-by-id'
            color='blue'
            :inversed='!isActiveTypeId'
            size='medium'
            @click='isActiveTypeId = true'>
            {{ $t('CUSTOMER.DETAILS.BUTTON.ID') }}
        </FwButton>
        <FwButton
            class='h-mh-10 qa-appointment-type-in'
            color='blue'
            :inversed='isActiveTypeId'
            :disabled='isManualDisabled'
            size='medium'
            @click='isActiveTypeId = false'>
            {{ $t('CUSTOMER.DETAILS.BUTTON.TYPE.IN') }}
        </FwButton>
    </div>
    <div :style='{ opacity: isActiveTypeId ? 1 : 0 }'
         :class='isMobile() || popupStyle ? "h-mb-60" : "h-mb-60"'>
        <FwInput
            :value='code'
            type='text'
            name='code'
            class='b-custom-details__input qa-appointment-type-in-code'
            :disabled='!isActiveTypeId || isInputDisabled'
            :placeholder='$t(`CUSTOMER.DETAILS.INPUT.PLACEHOLDER`)'
            :error='getFieldErrorText("code")'
            inputStyle='white-bigger'
            @update:value='value => code = value'
            @keyup.native.enter='sendCodeHandler'
            @input='clearServerErrorsBase("code") || (error = null)'
            @blur='addCurrentInputToValidateArray("code")'>
        </FwInput>
    </div>
    <div>
        <FwButton
            class='h-mh-10 qa-appointment-set-type'
            size='medium'
            :disabled='disabled'
            borderRadiusType='tiny-border'
            @click='sendCodeHandler'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop, Emit, Watch } from 'vue-property-decorator';

import PermissionsMixin from '@/mixins/permissions';
import ValidationMixin from '@/mixins/validation';
import AccountMixin from '@/mixins/account';

@Component({
    validators: {
        code(value: string) {
            return this.baseInputValidator({ value, maxLength: 100, minLength: 4 });
        },
    },
})
export default class CustomDetailsForm extends Mixins(PermissionsMixin, ValidationMixin, AccountMixin) {
    @Prop({ type: Boolean, default: false }) readonly popupStyle!: string;
    @Prop({ type: Boolean, required: true }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: `` }) readonly startCode!: string;
    @Prop({ type: Object, required: true }) readonly serverErrors!: { code: string | null };

    isActiveTypeId: boolean = true;
    code: string = ``;

    get disabled(): boolean {
        if (!this.isActiveTypeId) {
            return false;
        }
        return !this.isFormValid || this.isRequestSending;
    }

    get isManualDisabled(): boolean {
        return this.isAlmAccount || this.isHesAccount;
    }

    get isInputDisabled(): boolean {
        return this.isAlmAccount;
    }

    @Emit('sendCode')
    sendCodeHandler() {
        this.addCurrentInputToValidateArray('code');
        return {
            code: this.code,
            isActiveTypeId: this.isActiveTypeId,
        };
    }
    @Watch('$route')
    routeChange() {
        this.clearServerErrorsBase('code');
    }

    mounted() {
        if (!this.clientApiSupport) {
            this.isActiveTypeId = false;
            this.sendCodeHandler();
        }

        if (this.startCode) {
            this.code = this.startCode;
            this.addCurrentInputToValidateArray('code');
        }
    }
}
</script>

<style lang='sass'>
.b-intervention-code_main
    &--popup
        .b-intervention-code__inputs-wrapper
            margin-bottom: 30px

.b-custom-details
    &__input
        width: 330px
        .fw-input__inner
            width: 100%
</style>
