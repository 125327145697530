<template>
<div class='b-calendar-header__wrapper'>
    <div class='b-calendar-header'>
        <div class='b-calendar-header__logo'>
            <RouterLink
                to='/consult/choose'>
                <img :src='requireImage(`${mainLogo}.svg`)' alt='loo'>
            </RouterLink>
        </div>
        <div class='b-calendar-header__nav'>
            <CalendarHeaderNav
                :calendarDate='calendarDate'
                :hideBackButton='hideBackButton'
                :hideForwardButton='hideForwardButton'
                @changeDay='changeDayHandler'>
                <template #main-button>
                    <FwButton
                        class='b-calendar-header-nav__button h-uppercase'
                        color='choose'
                        size='little'
                        borderRadiusType='small-border'
                        fontSize='12px'
                        @click.native='changeDayHandler({ delta: 0, date: new Date() })'>
                        {{ $t('CALENDAR.CURRENT_MONTH') }}
                    </FwButton>
                </template>

                <template #current-time>
                    {{ $t(currentMonthName) }} {{ currentYear }}
                </template>

                <template
                    v-if='isConsultCalendarServicePoint'
                    #extra-title>
                    {{ $t('SERVICES_POINT.SHIFT.TXT') }}
                </template>

                <template #right-block>
                    <FwButton
                        class='b-calendar-header-nav__button b-calendar-header-nav__button--small h-mr-10'
                        color='choose'
                        size='little'
                        borderRadiusType='small-border'
                        fontSize='12px'
                        @click.native='$emit("openSettingsPopup")'>
                        <FwIcon
                            class='h-mr-5 h-mt-2'
                            icon='settings'
                            size='11'
                            color='#213F6B'>
                        </FwIcon>
                        <span class='h-mt-1'>
                            {{ $t('HEADER.SETTINGS') }}
                        </span>
                    </FwButton>

                    <FwButton
                        v-if='!hideHelpButton'
                        class='b-calendar-header-nav__button b-calendar-header-nav__button--small h-mr-10'
                        color='choose'
                        size='little'
                        borderRadiusType='small-border'
                        fontSize='12px'
                        @click.native='$emit("openHotToUsePopup")'>
                        <b class='h-mr-5 h-font-14 h-mt-1'>
                            ?
                        </b>
                        <span class='h-mt-2'>
                            {{ $t('HEADER.HELP') }}
                        </span>
                    </FwButton>
                </template>
            </CalendarHeaderNav>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';
import { CalendarHeaderNav } from '@/components/calendar/CalendarHeaderNav';
import { GoBackButton } from '@/components/simple/GoBackButton';

import { MONTHS } from '@/helpers/dates';

import CalendarMixin from '@/mixins/calendar';
import AccountMixin from '@/mixins/account';

@Component({
    components: {
        GoBackButton,
        CalendarHeaderNav,
    },
})
export default class CalendarHeader extends Mixins(CalendarMixin, AccountMixin) {
    @Prop({ type: Date, required: true }) readonly calendarDate!: Date;
    @Prop({ type: Date, required: true }) readonly hideBackButton!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideForwardButton!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideHelpButton!: boolean;

    get currentMonthName(): string {
        return this.calendarDate ? MONTHS[this.calendarDate.getMonth()] : '';
    }

    get currentYear(): string {
        return this.calendarDate ? `${this.calendarDate.getFullYear()}` : '';
    }

    @Emit('changeDay')
    changeDayHandler(data: { delta: number, date: Date }) {
        return data;
    }
}
</script>

<style lang='sass'>
.b-calendar-header
    display: flex
    align-items: center
    justify-content: space-between
    height: 100%

    &__logo
        width: 350px
        flex: 0 0 350px
        display: flex
        align-items: flex-start

    &__nav
        height: 100%
        width: 100%
        padding: 20px 60px

    &__button
        cursor: pointer
        width: 28px
        height: 28px
        border-radius: 5px
        display: flex
        align-items: center
        justify-content: center
        background-color: #E9ECF0
        transition: background 0.3s
        color: #8495AC
        font-weight: bold
        user-select: none

        &:hover
            background-color: #e3e4e5

        span
            display: flex

    &__back-arrow
        margin-left: 20px
        position: static

    &__title
        font-size: 20px

    &__wrapper
        width: 100%

    &__logo
        padding-left: 40px
        z-index: 1
</style>
