<template>
<div class='b-page-container b-consult-service-point'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='h-flex h-flex-center h-flex-space-between'>
            <div class='h-flex h-align-items-center'>
                <h1 v-if='servicePoint'
                    class='h-pr-20 qa-service-point-page-title'>
                    {{ servicePoint.name | capitalize }}
                </h1>
            </div>
            <div class='h-flex'>
                <div :class='{ "h-m-10": isMobile() }'>
                    <FwButton
                        v-if='currentUserRole && currentUserRole.service_point_rights.read'
                        :to='`/consult/calendar/service-point/${$route.params.id}`'
                        class='qa-service-point-calendar h-white-space-nowrap'
                        size='little'
                        borderRadiusType='small-border'
                        fontSize='12px'>
                        {{ $t('BUTTON.SEE_CALENDAR') }}
                    </FwButton>
                </div>
            </div>
        </div>
        <div class='b-consult-card__wrapper'>
            <div class='h-flex h-flex-dir-column h-flex-justify-center h-flex-1-0'
                 :class='{ "h-mb-10": isMobile() }'>
                <div class='b-consult-card h-mh-15'>
                    <div class='b-consult-card__title'>
                        <div>
                            {{ $t('HEADER.INFORMATION') }}
                        </div>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer qa-service-point-page-edit-button'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isServicePointEditPopup = true'>
                        </FwIcon>
                    </div>
                    <div class='b-consult-card__inner b-consult-card__inner--padding b-consult-card__scroll-inner'>
                        <template>
                            <ConsultCard
                                v-for='information in infoData'
                                :key='information.title'
                                :class='information.qa'
                                :information='information'>
                            </ConsultCard>
                            <div v-if='servicePoint'>
                                <div class='h-mt-40 h-flex h-flex-space-between'>
                                    <div class='b-consult-card__address h-fw-700'>
                                        {{ $t('COMPANIES.FORM.INPUT.ADDRESS.LABEL') }}
                                    </div>
                                    <FwIcon
                                        v-if='canEdit && false'
                                        class='h-pointer'
                                        icon='edit-settings'
                                        size='25'
                                        color='#BEC7D4'
                                        @click.native='isAddressUpdatePopup = true'>
                                    </FwIcon>
                                </div>
                                <div class='b-consult-card__sub-aside qa-service-point-address'>
                                    {{ servicePoint.address }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div v-if='currentUserRole && currentUserRole.advisor_rights.list'
                 class='h-flex h-flex-dir-column h-flex-justify-center h-flex-1-0 b-consult-card__410'>
                <WorkingHoursUpdateInfo
                    v-if='addressUpdateData'
                    isAddress>
                </WorkingHoursUpdateInfo>

                <div class='b-consult-card b-consult-card--wh h-mh-15 qa-branch-wh'>
                    <div class='h-flex h-flex-center h-flex-space-between'>
                        <div class='b-consult-card__title--wh'>
                            {{ $t('WORKER.TIME.SLOT.TITLE') }}
                        </div>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isWorkingHoursPopup = true'>
                        </FwIcon>
                    </div>
                    <WorkingHoursUpdateInfo v-if='workerUpdateData'></WorkingHoursUpdateInfo>
                </div>

                <div
                    v-if='currentUserRole.shift_rights.read'
                    class='
                        qa-manage-shifts
                        b-consult-card
                        b-consult-mt
                        b-consult-card--arrow
                        h-mh-15
                        h-flex
                        h-align-items-center
                        h-flex-space-between'
                    @click='isShiftPopupOpen = true'>
                    <span class='b-consult-card__title b-consult-card__title--base'>
                        {{ $t('SERVICES_POINT.SHIFT.TXT') }}
                    </span>
                    <div>
                        <FwIcon
                            class='h-mh-5 h-mr-30'
                            icon='arrow-right'
                            size='20'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </div>

                <ConsultCardArrow
                    :id='$route.params.id'
                    class='qa-branch-closures-name'
                    :title='$t("CONSULT.CLOSURES")'
                    type='hub'
                    @click.native='isClosuresPopupOpen = true'>
                </ConsultCardArrow>

                <router-link
                    v-if='currentUserRole.kpis_rights.read && false'
                    :to='`/consult/calendar/service-point/${$route.params.id}`'
                    class='b-consult-card b-consult-mt b-consult-card--arrow h-mh-15 h-flex h-align-items-center h-flex-space-between'>
                    <span class='b-consult-card__title b-consult-card__title--base'>
                        {{ $t('SETTINGS.ROLE.APPOINTMENT.STATISTICS') }}
                        <FwIcon
                            v-if='accessLockStatisticPage'
                            class='b-icon-lock h-ml-10'
                            icon='lock-blue'
                            size='15'
                            color='#203f6a'>
                        </FwIcon>
                    </span>
                    <div>
                        <FwIcon
                            class='h-mh-5 h-mr-30'
                            icon='arrow-right'
                            size='20'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </router-link>
            </div>
        </div>
    </template>
    <ShiftsPopup
        v-if='isShiftPopupOpen'
        @close='isShiftPopupOpen = false'>
    </ShiftsPopup>
    <AddOrEditServicePoint
        v-if='isServicePointEditPopup'
        :servicePoint='servicePoint'
        @updateState='getServicePointData'
        @remove='remove'
        @closePopup='isServicePointEditPopup = false'>
    </AddOrEditServicePoint>
    <AddClosuresPopup
        v-if='isClosuresPopupOpen'
        :id='$route.params.id'
        :readonly='!currentUserRole.branch_rights.update'
        type='service_point'
        @close='isClosuresPopupOpen = false'>
    </AddClosuresPopup>
    <AddOrEditAddressByForm
        v-if='isAddressUpdatePopup'
        :objectData='servicePoint'
        updateAction='ValidateSiteAddress'
        updateType='Site'
        isAddress
        @close='isAddressUpdatePopup = false'>
    </AddOrEditAddressByForm>
    <WorkingHoursPopup
        v-if='isWorkingHoursPopup'
        :servicePointsScheduleId='servicePoint.working_schedule_id'
        :actionUpdate='`ValidateWorkingHoursServicePoint`'
        @close='isWorkingHoursPopup = false'>
    </WorkingHoursPopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { workerDataType } from '@/types/User';
import { SitesApi } from '@/api/sites/SitesApi';
import { CompanyInformationDataType } from '@/types/Companies';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { WorkingHoursPopup } from '@/components/popups/WorkingHoursPopup';
import { BranchSitesDataType } from '@/types/Site';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { WorkerScheduleTimeType } from '@/types/Workers';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';
import { AddOrEditAddressByForm } from '@/views/consult/update-address/AddOrEditAddressByForm';
import PermissionsMixin from '@/mixins/permissions';
import { AddClosuresPopup } from '@/components/popups/AddClosuresPopup';
import { ShiftsPopup } from '@/components/popups/ShiftsPopup';
import { ConsultCardArrow } from '@/components/simple/ConsultCardArrow';
import { AddOrEditServicePoint } from '@/views/consult/add-service-point/AddOrEditServicePoint';
import { ServicePointItemType } from '@/types/ServicePoint';

const AppointmentStore = namespace('AppointmentStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        AddOrEditServicePoint,
        ConsultCard,
        InformationPopup,
        WorkingHoursPopup,
        GoBackButton,
        WorkingHoursUpdateInfo,
        AddOrEditAddressByForm,
        AddClosuresPopup,
        ConsultCardArrow,
        ShiftsPopup,
    },
})
export default class ConsultServicePointPage extends Mixins(PermissionsMixin) {
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;

    servicePoint: ServicePointItemType | null = null;
    isServicePointEditPopup: boolean = false;
    isClosuresPopupOpen: boolean = false;
    isAddressUpdatePopup: boolean = false;
    isWorkingHoursPopup: boolean = false;
    isPreventPopupShow: boolean = false;
    isShiftPopupOpen: boolean = false;
    loading: boolean = false;

    get infoData(): Array<CompanyInformationDataType> {
        const branchDataArray: Array<CompanyInformationDataType> = [];
        if (this.servicePoint) {
            if (this.servicePoint.phone) {
                branchDataArray.push({
                    title: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
                    aside: this.servicePoint.phone,
                    qa: 'service-point-phone',
                });
            }
            if (this.servicePoint.email) {
                branchDataArray.push({
                    title: this.$i18n.t('LABEL.EMAIL'),
                    aside: this.servicePoint.email,
                    qa: 'service-point-email',
                });
            }
            if (this.servicePoint.capacity) {
                branchDataArray.push({
                    title: this.$i18n.t('LABEL.SERVICE_POINT_CAPACITY'),
                    aside: this.servicePoint.capacity,
                    qa: 'service-point-capacity',
                });
            }
            if (this.servicePoint.external_id) {
                branchDataArray.push({
                    title: this.$i18n.t('LABEL.SERVICE_POINT.ID'),
                    aside: this.servicePoint.external_id,
                    qa: 'service-point-external_id',
                });
            }
        }
        return branchDataArray;
    }

    remove(): void {
        this.$router.go(-1);
    }

    async getServicePointData(): Promise<void> {
        if (this.$route.params.id) {
            this.loading = true;
            const data = await SitesApi.getServicePoint(this.$route.params.id);
            this.servicePoint = data.service_point;
            this.loading = false;
        }
    }

    created(): void {
        this.getServicePointData();
    }
}
</script>

<style lang='sass'>
.b-consult-service-point
    @include media('<=phone')
        padding: 0 15px 50px

    .b-consult-card, .b-consult-card--wh
        flex: 1 0
        border-radius: 20px

        &--arrow
            cursor: pointer

        &--small
            flex: 0 0 320px

        &--transparent
            background-color: transparent
            box-shadow: none !important

        &__title
            color: $dark-blue
            font-weight: 600
            display: flex
            justify-content: space-between

            &--base
                padding: 20px 30px 16px !important

        &__wrapper
            margin: 0 -15px
            display: flex
            justify-content: space-between
</style>
