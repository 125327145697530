<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :max-width='isMobile() ? "92%" : "80%"'
        width='900px'
        padding='30px 40px 0'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :disableClosingOnClickOutside='true'
        @close='$emit(`close`)'>
        <template #body>
            <div class='h-text-center h-mt-25'>
                <h1 class='h-font-22'>
                    {{ $t('PAST.APPOINTMENT.SET_BOOK.TITLE') }}
                </h1>
            </div>
            <div class='b-book-appointment__popup'>
                <form class='b-book-appointment__form'>
                    <FwFormInput
                        class='h-flex-1-0'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.PHONE`)}`'>
                        <FwInput
                            :value='phone_number'
                            type='number'
                            name='text'
                            :placeholder='$t(`LABEL.PHONE`)'
                            inputStyle='white-bigger'
                            :isPhoneWithISOCode='isGeoScopeInternationalWithCountries'
                            :locales='["fr", "ch"]'
                            :tryToUseSubstring='isGeoScopeInternationalWithCountries'
                            :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                            @input='clearServerErrorsBase(BOOKING_PHONE)'
                            @blur='addCurrentInputToValidateArray(BOOKING_PHONE)'
                            @takePhoneWithPrefix='takePhoneWithPrefix'
                            @update:value='value => phone_number = value'
                            @substrPhone='value => phone_number = value'>
                        </FwInput>
                    </FwFormInput>
                    <FwFormInput
                        class='h-flex-1-0 qa-appointment-book-email'
                        :fieldHasError='fieldHasError(BOOKING_EMAIL)'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.EMAIL`)}`'>
                        <FwInput
                            :value='email'
                            type='text'
                            name='text'
                            :placeholder='$t(`LABEL.EMAIL`)'
                            :error='getFieldErrorText(BOOKING_EMAIL)'
                            inputStyle='white-bigger'
                            :disabled='isEmailDisabled'
                            @update:value='value => email = value'
                            @input='clearServerErrorsBase(BOOKING_EMAIL)'
                            @blur='addCurrentInputToValidateArray(BOOKING_EMAIL)'>
                        </FwInput>
                    </FwFormInput>
                </form>

                <div class='h-flex h-flex-center h-pb-50'>
                    <FwButton
                        class='h-mt-10 qa-appointment-book-email-submit'
                        borderRadiusType='small-border'
                        :disabled='isDisable'
                        @click.native='submitForm'>
                        {{ $t('BUTTON.SUBMIT') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Emit } from 'vue-property-decorator';

import { BookingForm } from '@/components/forms/BookingForm';

import { ClientType } from '@/types/User';

import { BOOKING_PHONE, BOOKING_EMAIL } from '@/helpers/booking';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        BookingForm,
    },
    validators: {
        [BOOKING_PHONE](value: string) {
            return this.baseInputValidator({ value, isRequired: false });
        },
        [BOOKING_EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
})
export default class SetBookInfoPopup extends Mixins(ValidationMixin, PermissionsMixin) {
    @AppointmentStore.State('client') client!: ClientType | null;

    phoneWithPrefix: string = '';
    isRequestSending: boolean = false;
    [BOOKING_PHONE]: string = '';
    [BOOKING_EMAIL]: string = '';
    BOOKING_PHONE: string = BOOKING_PHONE;
    BOOKING_EMAIL: string = BOOKING_EMAIL;

    serverErrors: serverErrors = {
        [BOOKING_PHONE]: '',
        [BOOKING_EMAIL]: '',
    };

    get isDisable(): boolean {
        if (!this.email && !this.phone_number) {
            return true;
        }
        return !this.isFormValid || this.isRequestSending;
    }

    get isPhoneDisabled() {
        return Boolean(this.email && this.email.length);
    }

    get isEmailDisabled() {
        return Boolean(this.phone_number && this.phone_number.length);
    }

    get phoneNumber() {
        return this.isGeoScopeInternationalWithCountries ? this.phoneWithPrefix : this.phone_number;
    }

    takePhoneWithPrefix(phone: string) {
        this.phoneWithPrefix = phone;
    }

    async submitForm() {
        if (this.isDisable) return;
        this.isRequestSending = true;
        let client: null | any = null;
        if (this.email) {
            try {
                client = await AppointmentWebApi.getClientDetailsByEmail(this.email);
            } catch ({ response }) {
                this.sentNotif(response);
            }
        } else if (this.phone_number) {
            try {
                client = await AppointmentWebApi.getClientDetailsByPhone(this.phoneNumber);
            } catch ({ response }) {
                this.sentNotif(response);
            }
        }
        this.isRequestSending = false;
        this.clientHandler({ client, email: this.email, phone_number: this.phone_number, phoneWithPrefix: this.phoneWithPrefix });
    }

    @Emit('gotClientData')
    clientHandler(payload: { client: null | ClientType, email: string, phone_number: string, phoneWithPrefix: string }) {}

    mounted() {
        this.addCurrentInputToValidateArray(BOOKING_PHONE);
        this.addCurrentInputToValidateArray(BOOKING_EMAIL);
    }
}
</script>

<style lang='sass'>
.b-book-appointment
    &__form
        padding-top: 15px
        margin: 0 auto
        display: flex

        @include media('>phone')
            width: 80%
            margin-bottom: 25px

            .fw-form-input__wrapper
                margin: 0 20px

        @include media('<=phone')
            width: 100%
            flex-direction: column

            .fw-form-input__wrapper
                margin-bottom: 15px

    &__popup
        .b-cancel-appointment__form__events
            margin-bottom: 20px

.fw-button--transparent
    background-color: transparent !important

.b-book-appointment__popup
    .b-form-base-line
        @include media('<=phone')
            padding-bottom: 0

</style>
