<template>
<div class='b-appointment-info'>
    <div class='h-flex h-flex-space-between h-align-items-center'>
        <router-link
            to='/appointment/matmut/fetch-details'>
            <img :src='requireImage(`${mainLogo}.svg`)'>
        </router-link>
    </div>
    <slot></slot>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { ConsultCard } from '@/components/simple/ConsultCard';
import TranslateMixin from '@/mixins/TranslateMixin';
import AccountMixin from '@/mixins/account';

const AppointmentStore = namespace('AppointmentStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component({
    components: {
        ConsultCard,
    },
})
export default class PlanAppointmentInfo extends Mixins(TranslateMixin, AccountMixin) {}
</script>

<style lang='sass'>
.b-appointment-info__consult-info
    position: fixed
    bottom: 0
    left: 0
    max-width: 350px
</style>
