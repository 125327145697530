import axios from 'axios';
import { updateUrlGetParams } from '@/helpers/url';
import { BranchSitesDataType } from '@/types/Site';
import {
    BranchRoomResponseType,
    CreateBranchPayloadType,
    pagePaginationData,
    HubCreateType,
    BranchByPostCodeItemOption,
    ServicePointsByPostCodeItemOption,
    ServicePointsByPostCodeWithDistanceItemOption,
    DistanceByPostCodeItemOption,
} from '@/types/Appointment';
import WebApi from '../WebApi';
import {
    ServicePointFormDataPayloadType,
    ServicePointItemResponseType,
    ServicePointsListResponseType,
} from '@/types/ServicePoint';
import { WorkerScheduleTimeType } from '@/types/Workers';

class SitesApi extends WebApi {
    async getSites(code: string): Promise<Array<BranchSitesDataType>> {
        const params: { [key: string]: string } = {
            kind: `branch`,
            post_code: code,
        };

        const { data } = await axios.get(
            updateUrlGetParams(`${this.urlPrefix}/sites`, params)
        );
        return data.sites;
    };

    async getSite(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/sites/${id}`
        );
        return data;
    }

    async deleteSite(id: string) {
        const { data } = await axios.delete(
            `${this.urlPrefix}/sites/${id}`
        );
        return data;
    }

    async getHub(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/hubs/${id}`
        );
        return data;
    }

    async activateHub(id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/hubs/${id}/activate_hub`
        );
        return data;
    }

    async activateBranch(id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/sites/${id}/activate_branch`
        );
        return data;
    }

    async getSitesHubs(payload: pagePaginationData) {
        const sort = payload.sort_by_count ? `&sort_by_count=${payload.sort_by_count}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sort}`;
        const { data } = await axios.get(
            `${this.urlPrefix}/hubs${urlParams}`
        );
        return data;
    }
    async createHub(hub: HubCreateType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/hubs`,
            { hub }
        );
        return data;
    }
    async updateHub(hub: HubCreateType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/hubs/${id}`,
            { hub }
        );
        return data;
    }
    async deleteHub(id: string) {
        const { data } = await axios.delete(
            `${this.urlPrefix}/hubs/${id}`,
        );
        return data;
    }
    async createSite(site: CreateBranchPayloadType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/sites`,
            { site }
        );
        return data;
    }
    async updateSite(site: BranchRoomResponseType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/sites/${id}`,
            { site }
        );
        return data;
    }

    async getBranchesByPostCode(
        post_code: string,
        rooms: boolean,
        branch_external_id: string | null = null,
        payload: pagePaginationData,
        kind = 'branch'
    ): Promise<BranchByPostCodeItemOption> {
        const country_iso = payload.country_iso as string;
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(updateUrlGetParams(
            `${this.urlPrefix}/sites${urlParams}`,
            {
                post_code,
                kind,
                locations: true,
                ...(rooms && { rooms }),
                ...(branch_external_id && { branch_external_id }),
                ...(country_iso && { country_iso }),
            }
        ));
        return data;
    }

    async getServicePointsByPostCode(post_code: string, payload: pagePaginationData): Promise<ServicePointsByPostCodeItemOption> {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(updateUrlGetParams(
            `${this.urlPrefix}/service_points${urlParams}`,
            {
                post_code,
            }
        ));
        return data;
    }
    // eslint-disable-next-line max-len
    async getBranchesByPostCodeWithDistance({ kind, origin, post_code, current_page, per_page }: DistanceByPostCodeItemOption): Promise<ServicePointsByPostCodeWithDistanceItemOption> {
        const urlParams = `?pagination[per_page]=${per_page}&pagination[page]=${current_page}`;
        const { data } = await axios.get(updateUrlGetParams(
            `${this.urlPrefix}/sites/order${urlParams}`,
            {
                kind,
                ...(origin && { origin }),
                post_code,
            }
        ));
        return data;
    }

    async getSiteAvatars(site: string, payload: pagePaginationData) {
        const sort = payload.sort_by_count ? `&sort_by_count=${payload.sort_by_count}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sort}`;
        const { data } = await axios.get(`${this.urlPrefix}/sites?kind=${site}&avatars=true${urlParams}`);
        return data;
    }

    async getSitesBySearch(payload: pagePaginationData, searchValue: string, kind: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/sites?kind=${kind}&search=${searchValue}${urlParams}`);
        return data;
    }

    async getBranchBySearch(payload: pagePaginationData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/hubs${urlParams}&search=${searchValue}`);
        return data;
    }

    async getServicePointsBySearch(payload: pagePaginationData, searchValue: string): Promise<ServicePointsListResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/service_points`, {
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            ...(payload.sort_by_count && { sort_by_count: payload.sort_by_count }),
            ...(searchValue && { search: searchValue }),
        }));
        return data;
    }

    async getServicePoint(id: string): Promise<ServicePointItemResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/service_points/${id}`);
        return data;
    }

    async createServicePoint(service_point: ServicePointFormDataPayloadType): Promise<ServicePointItemResponseType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/service_points`,
            { service_point }
        );
        return data;
    }

    async updateServicePoint(service_point: ServicePointFormDataPayloadType, id: string): Promise<ServicePointItemResponseType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/service_points/${id}`,
            { service_point }
        );
        return data;
    }

    async deleteServicePoint(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/service_points/${id}`);
        return data;
    }

    async getServicePointSchedulesData(id: string): Promise<any> {
        const { data } = await axios.get(`${this.urlPrefix}/service_point_working_schedules/${id}`);
        return data;
    }

    async updateServicePointSchedulesData(payload: WorkerScheduleTimeType, currentData: Date, id: string): Promise<any> {
        const { data } = await axios.put(`${this.urlPrefix}/service_point_working_schedules/${id}`, {
            'working_schedule': payload,
        },);
        return data;
    }
}

const sitesApi = new SitesApi();

export {
    sitesApi as SitesApi,
};
