<template>
<div class='b-page-container b-consult-hub'>
    <div class='b-consult-card__inner'>
        <GoBackButton
            class='h-mt-30'
            isRelativePosition>
        </GoBackButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='h-flex h-flex-center h-flex-space-between h-mb-10'>
            <div class='b-common-item-title-wrapper'>
                <h1 v-if='hubStateData' class='h-pr-20 qa-hub-page-title'>
                    {{ hubStateData.name | capitalizeAll }}
                </h1>
                <!--ONLY FOR STAGING-->
                <FwButton
                    v-if='canEdit && isNotProd'
                    class='h-pl-20'
                    color='green'
                    size='little'
                    borderRadiusType='tiny-border'
                    fontSize='12px'
                    :loading='isActivateLoading'
                    @click='activate'>
                    {{ $t('Activate') }}
                </FwButton>
            </div>
            <div class='b-common-item-title-wrapper__inner'>
                <FwButton
                    v-if='currentUserRole && currentUserRole.calendar_entity_rights.read'
                    :to='`/consult/calendar/hub/${$route.params.id}`'
                    class='h-white-space-nowrap'
                    size='little'
                    fontSize='12px'
                    borderRadiusType='small-border'>
                    {{ $t('BUTTON.SEE_CALENDAR') }}
                </FwButton>
            </div>
        </div>
        <div class='b-consult-card__wrapper'>
            <div class='h-flex h-flex-dir-column h-flex-justify-center'>
                <div class='b-consult-hub__info-wrapper'>
                    <div class='b-consult-card h-mh-15'>
                        <div class='b-consult-card__title'>
                            <div>
                                {{ $t('HEADER.INFORMATION') }}
                            </div>
                            <FwIcon
                                v-if='canEdit'
                                class='h-pointer qa-hub-page-edit-button'
                                icon='edit-settings'
                                size='25'
                                color='#BEC7D4'
                                @click.native='isHubEditPopup = true'>
                            </FwIcon>
                        </div>
                        <div class='b-consult-card__inner b-consult-card__inner--padding b-consult-card__scroll-inner'>
                            <ConsultCard
                                v-for='information in prepareData(hubStateData)'
                                :key='information.title'
                                :information='information'>
                            </ConsultCard>
                        </div>
                    </div>
                    <ConsultCardArrow
                        :id='$route.params.id'
                        :title='$t("CONSULT.CLOSURES")'
                        type='hub'
                        @click.native='isClosuresPopupOpen = true'>
                    </ConsultCardArrow>
                </div>
                <router-link
                    v-if='currentUserRole ? currentUserRole.kpis_rights.read : false'
                    :to='`/consult/hub/statistic/${$route.params.id}/appointment-booked-types/for`'
                    class='b-consult-card b-consult-card--statistics b-consult-mt b-consult-card--arrow h-mh-15 h-flex h-align-items-center h-flex-space-between'>
                    <span class='b-consult-card__title b-consult-card__title--base'>
                        {{ $t('SETTINGS.ROLE.APPOINTMENT.STATISTICS') }}
                        <FwIcon
                            v-if='accessLockStatisticPage'
                            class='b-icon-lock h-ml-10'
                            icon='lock-blue'
                            size='15'
                            color='#203f6a'>
                        </FwIcon>
                    </span>
                    <div>
                        <FwIcon
                            class='h-mh-5 h-mr-30'
                            icon='arrow-right'
                            size='20'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </router-link>
            </div>
            <div v-if='currentUserRole && currentUserRole.advisor_rights.list'
                 class='b-consult-card h-mh-15'>
                <div class='b-consult-card__title h-flex h-flex-space-between h-align-items-center'>
                    <span>
                        {{ $t('BREADCRUMBS.ADVISORS') }}
                    </span>
                    <div class='b-consult-button-size'>
                        <FwButton
                            v-if='currentUserRole.advisor_rights.create'
                            class='h-flex h-flex-center h-text-center qa-hub-add-advisor'
                            size='small'
                            borderRadiusType='small-border'
                            fontSize='12px'
                            @click.native='addWorkerMethod'>
                            {{ $t('BUTTON.ADD') }}
                        </FwButton>
                    </div>
                </div>
                <FwInput
                    v-if='workersData.length'
                    class='h-flex-1 h-ph-30 h-mt-20'
                    :value='searchText'
                    :placeholder='$t(`WORKERS.SEARCH.PLACEHOLDER`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>

                <div v-if='preparedAdvisorsCards.length !== 0'
                     class='b-consult-card__scroll-inner h-mt-15'>
                    <component
                        :is='currentUserRole.advisor_rights.read ? "router-link" : "div"'
                        v-for='worker in preparedAdvisorsCards'
                        :key='worker.id'
                        :to='currentUserRole.advisor_rights.read ? { path: `/consult/advisor/${worker.id}` } : false'
                        class='b-consult-card__worker-wrapper qa-advisor-hub-to-advisor'
                        :class='[{
                            "h-pointer": currentUserRole && currentUserRole.advisor_rights.read
                        }, `qa-${worker.first_name ? worker.first_name.toLowerCase() : ""}`]'>
                        <WorkerAvatar
                            :worker='worker'
                            nameClass='b-consult-card__worker-name'
                            size='small'
                            :hideRole='true'
                            align='right'>
                        </WorkerAvatar>
                        <div class='b-consult-card__worker-arrow'>
                            <FwIcon
                                class='h-mh-5'
                                icon='arrow-right'
                                size='20'
                                color='#27dbbd'>
                            </FwIcon>
                        </div>
                    </component>
                </div>
            </div>
        </div>
    </template>
    <AddClosuresPopup
        v-if='isClosuresPopupOpen'
        :id='$route.params.id'
        :readonly='!currentUserRole.hub_rights.update'
        type='hub'
        @close='isClosuresPopupOpen = false'>
    </AddClosuresPopup>
    <AddOrEditHubsByForm
        v-if='isHubEditPopup'
        @closePopup='isHubEditPopup = false'>
    </AddOrEditHubsByForm>
    <AddEditAdvisorPopup
        v-if='isAddEditAdvisorPopup'
        :type='`hub`'
        :parent_id='hubStateData.id'
        isBranchAdvisorAdd
        isBranchHubHide
        @closePopup='closeAdvisorAddPopup'>
    </AddEditAdvisorPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { workerDataType } from '@/types/User';
import { CompanyInformationDataType } from '@/types/Companies';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { AddEditRoomPopup } from '@/components/popups/AddEditRoomPopup';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup';
import { AddOrEditHubsByForm } from '@/views/consult/add-hubs/AddOrEditHubsByForm';
import { SitesApi } from '@/api/sites/SitesApi';
import { BranchSitesDataType } from '@/types/Site';
import { GoBackButton } from '@/components/simple/GoBackButton';
import PermissionsMixin from '@/mixins/permissions';
import { ConsultCardArrow } from '@/components/simple/ConsultCardArrow';
import { AddClosuresPopup } from '@/components/popups/AddClosuresPopup';
import { capitalizeAll } from '@/helpers/string';

const AppointmentStore = namespace('AppointmentStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
        AddEditRoomPopup,
        AddEditAdvisorPopup,
        AddOrEditHubsByForm,
        GoBackButton,
        ConsultCardArrow,
        AddClosuresPopup,
    },
})
export default class ConsultHubPage extends Mixins(PermissionsMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @WorkerPageStore.Mutation('setHubData') setHubData!: Function;
    @WorkerPageStore.State('hubStateData') hubStateData!: BranchSitesDataType;

    roomId: string | null = null;
    currentRoomData: any | null = null;
    searchText: string = '';
    informationData: Array<CompanyInformationDataType> = [];
    workersData: Array<workerDataType> = [];
    createdAdvisor: workerDataType | null = null;
    loading: boolean = true;
    isAddEditAdvisorPopup: boolean = false;
    isActivateLoading: boolean = false;
    isHubEditPopup: boolean = false;
    isClosuresPopupOpen: boolean = false;

    get isNotProd() {
        return process.env.VUE_APP_DEPLOY_TYPE !== `production`;
    }

    get preparedAdvisorsCards() {
        const arrAdvisors = this.createdAdvisor ? this.workersData.concat(this.createdAdvisor) : this.workersData;
        const filteredWorkers = this.searchText ? arrAdvisors.filter(worker => {
            return (
                // @ts-ignore-next-line
                worker.first_name && worker.first_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                worker.last_name && worker.last_name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        }) : arrAdvisors;
        return filteredWorkers;
    }
    async activate() {
        this.isActivateLoading = true;
        try {
            const data = await SitesApi.activateHub(this.$route.params.id);
            this.sentNotif('Successfully activated', true);
            this.isActivateLoading = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isActivateLoading = false;
        }
    }

    async getHubData() {
        try {
            const existHub = { hub: this.hubStateData };
            const data = this.hubStateData ? existHub : await SitesApi.getHub(this.$route.params.id);
            this.loading = false;
            this.setHubData(data.hub);
            this.workersData = data.hub.users;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    addWorkerMethod() {
        this.isAddEditAdvisorPopup = true;
        this.setGeograficCitiesData([]);
    }
    closeAdvisorAddPopup(data: workerDataType) {
        this.createdAdvisor = data;
        this.isAddEditAdvisorPopup = false;
        this.getHubData();
    }

    clearAddOrEditData() {
        this.roomId = null;
        this.currentRoomData = null;
    }

    prepareData(hub: BranchSitesDataType) {
        const hubDataArray: Array<CompanyInformationDataType> = [];
        if (hub && hub.name) {
            hubDataArray.push({
                title: this.$i18n.t('ONBOARDING.OPTION.TEAMS.FORM.TITLE'),
                aside: capitalizeAll(hub.name),
            });
        }
        return hubDataArray;
    }
    created() {
        this.setHubData(null);
        this.getHubData();
    }
}
</script>

<style lang='sass'>
.b-consult-hub
    &__info-wrapper
        flex: 1
        display: flex
        flex-direction: column

    .b-consult-card
        @include media('>phone')
            width: 400px

        &--small
            flex: 0 0 320px

        &--transparent
            background-color: transparent
            box-shadow: none!important

        &__wrapper
            margin: 0 -15px
            display: flex
            justify-content: space-between

            @include media('<=phone')
                margin: 0
                padding: 0 15px 10px

        &__worker-wrapper
            display: flex
            padding: 10px 30px
            align-items: center
            justify-content: space-between

            @include media('<=phone')
                padding: 10px 20px

            &:hover
                cursor: pointer
                background-color: $light-gray

                .b-consult-card__worker-arrow
                    transform: translate(5px, 0)

        &__worker-name
            flex: 1
            padding: 0
            font-size: 18px
            font-weight: 500
            color: $dark-blue
            max-width: 250px

            @include media('<=phone')
                max-width: calc(100vw - 170px)

        &__scroll-inner
            max-height: 400px
            overflow: auto

        &__worker-arrow
            transform: translate(0, 0)
            transition: transform .4s ease-in-out

    .b-consult-card--statistics
        flex: 0 0 69px
        @include media('<=phone')
            margin-bottom: 20px

        .b-consult-card__title
            padding-top: 0

.b-page-title
    padding: 20px 0 10px
    display: flex
    align-items: center
    justify-content: space-between

@include media('<=phone')
    .b-page-title
        flex-wrap: wrap
        padding: 20px 15px

        & > div:not(:first-of-type)
            margin-left: 15px

    .b-consult-card
        &__wrapper
            margin: 0 0 70px
            flex-flow: column
</style>
