<template>
<div class='b-add-appointment-type'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='h-text-center h-pt-30 h-pb-5'>
            <h1>
                {{ $t('BREADCRUMBS.CUSTOM.DETAILS') }}
            </h1>
        </div>
        <CustomDetailsForm
            class='b-intervention-code_main'
            :serverErrors='serverErrors'
            :isRequestSending='isRequestSending'
            :startCode='code'
            @sendCode='sendInterventionData'>
        </CustomDetailsForm>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppointmentCategoryType, AppointmentSearchType } from '@/types/Appointment';
import { ClientType } from '@/types/User';
import { CustomDetailsForm } from '@/components/forms/add/CustomDetailsForm';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import AccountMixin from '@/mixins/account';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    components: {
        CustomDetailsForm,
    },
})
export default class CustomDetailsTypePage extends Mixins(AccountMixin) {
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.Mutation('setAppointmentDataToUpdate') setAppointmentDataToUpdate!: (type: AppointmentSearchType | null) => void;
    @AppointmentStore.Mutation('setClientData') setClientData!: (client: ClientType | null) => void;
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @AppointmentStore.Mutation('setCurrentAppointmentKind') setCurrentAppointmentKind!: (categoryName: string) => void;
    @AppointmentStore.Mutation('setOpportunityId') setOpportunityId!: (id: string) => void;
    @AppointmentStore.Mutation('setCaseId') setCaseId!: (id: string) => void;

    value: AppointmentCategoryType | null = null;
    loading: boolean = false;
    isRequestSending: boolean = false;
    error: string = ``;
    code: string = ``;
    appointmentCategories: Array<AppointmentCategoryType> = [];
    serverErrors = {
        code: this.error,
    };

    async sendInterventionData({ code, isActiveTypeId }: { code: string, isActiveTypeId: boolean }) {
        if (isActiveTypeId) {
            try {
                const { sfa_id, sfo_id } = this.$route.query;

                this.isRequestSending = true;
                const payload = {
                    ...(this.isMgefiAccount && { sfa_id: code }),
                    ...(this.isAlmAccount && sfa_id !== undefined && { sfa_id: code }),
                    ...(this.isAlmAccount && sfo_id !== undefined && { sfo_id: code }),
                    ...(!this.isMgefiAccount && !this.isHesAccount && !this.isPlanifAccount && !this.isAlmAccount && { remote_client_id: code }),
                    ...(this.isHesAccount && { hs_token: code }),
                    ...(this.isPlanifAccount && code && {
                        [this.$route.query.sfl_id ? 'sfl_id' : 'sfa_id']: code,
                    }),
                };
                const client = await AppointmentWebApi.getClientDetails(payload);
                this.setClientData(client);
                this.setPostCode(client.post_code);
                this.$router.push({ path: '/appointment/select-type' }).catch(() => {});
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        } else {
            this.setClientData(null);
            this.$router.push({ path: '/appointment/select-type' });
        }
    }

    async created() {
        this.loading = false;
        if (this.isMgefiAccount && this.$route.query.sfa_id && typeof this.$route.query.sfa_id === 'string') {
            this.code = String(this.$route.query.sfa_id);
        } else if (this.isAlmAccount && this.$route.query.sfa_id && typeof this.$route.query.sfa_id === 'string') {
            this.code = String(this.$route.query.sfa_id);
        } else if (this.isAlmAccount && this.$route.query.sfo_id && typeof this.$route.query.sfo_id === 'string') {
            this.code = String(this.$route.query.sfo_id);
        } else if (this.$route.query.client_number) {
            this.code = String(this.$route.query.client_number);
        } else if (
            this.isHesAccount &&
            this.$route.query.hs_site_id && typeof this.$route.query.hs_site_id === 'string' &&
            this.$route.query.hs_lead_id && typeof this.$route.query.hs_lead_id === 'string'
        ) {
            this.code = `${this.$route.query.hs_site_id}//${this.$route.query.hs_lead_id}`;
        } else if (
            this.isPlanifAccount &&
            (
                this.$route.query.sfl_id && typeof this.$route.query.sfl_id === 'string' ||
                this.$route.query.sfa_id && typeof this.$route.query.sfa_id === 'string'
            )
        ) {
            this.code = `${this.$route.query.sfl_id || this.$route.query.sfa_id}`;
        }
        const opportunity_id: string = Array.isArray(this.$route.query.opportunity_id) ? '' : this.$route.query.opportunity_id || '';
        const case_id: string = Array.isArray(this.$route.query.case_id) ? '' : this.$route.query.case_id || '';
        this.setOpportunityId(opportunity_id);
        this.setCurrentAppointmentKind('');
        this.setCaseId(case_id);
        this.setAppointmentDataToUpdate(null);
    }
}
</script>

<style lang='sass'>
.b-society-selection
    .multiselect__option--highlight
        background: transparent!important
    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important

.b-add-appointment-type
    .multiselect__content-wrapper
        max-height: 350px !important

.b-intervention-code_main
    .fw-input__wrapper
        display: flex
        justify-content: center
        align-items: center
        .fw-input__error-text
            font-size: 14px!important
            margin-top: 10px
            bottom: -10px
            left: auto
            text-align: center
            min-width: 350px

.b-intervention-code__inputs-wrapper
    display: flex
    margin-bottom: 50px
.b-intervention-code_main
    .fw-input__wrapper
        display: flex
        justify-content: center
        align-items: center
        .fw-input__error-text
            font-size: 14px!important
            margin-top: 10px
            bottom: -10px
            left: auto
            text-align: center
            min-width: 350px
    .fw-input--white-bigger::placeholder
        color: $hard-gray
    @include media('<tablet')
        margin-bottom: 35px
</style>
