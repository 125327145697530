import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import i18n from '@/locale';
import { ServerBaseDataOnError } from '@/types/Response';
import { getErrorsTemplate } from '@/helpers/translate';
import { ValidatorType, validatorsToTemplateErrorMapping, isValid } from '@/components/forms/attributes/helpers';
import { AttributeExtendedType } from '@/types/Attribute';

declare module 'vue/types/vue' {
    interface Vue {
        $validate: Function,
        validation: any,
        serverErrors: serverErrors, // need to declare on main component!
        config: Function, // need to declare on main component!
        emailValidator: ({ value }: { value: string, notRequired?: boolean, baseInputValidator?: string, requiredHint?: string }) => boolean,
        // eslint-disable-next-line max-len
        baseInputValidator: ({ value, minLength, maxLength, isRequired, baseInputValidator, requiredHint }: { value: string, minLength?: number, maxLength?: number, isRequired?: boolean, baseInputValidator?: string, requiredHint?: string }) => boolean,
    }
}

@Component
export default class ValidationMixin extends Vue {
    validationActiveArray: Array<string> = [];

    activateValidation(): Promise<boolean> {
        return this.$validate();
    }

    getErrorTextByValidator(validator: ValidatorType, value: string, schema?: AttributeExtendedType) {
        const validatorValue = schema ? schema.validator[validator] : this.schema.validator[validator];
        const currentTempKey = validatorsToTemplateErrorMapping[validator];
        // @ts-ignore
        const errorsTemplate = getErrorsTemplate(this.$i18n.locale)[currentTempKey];
        return errorsTemplate.includes(`{0}`) ? errorsTemplate.replace(`{0}`, validatorValue) : errorsTemplate;
    }

    getValidatorErrors(validators: Array<ValidatorType>, value: any, schema?: AttributeExtendedType): Array<string> {
        const hasValidator = Array.isArray(validators) && validators.length > 0;
        const errors: Array<any> = [];
        if (hasValidator) {
            validators.forEach((item: ValidatorType) => {
                if (!this.isValid(value, item, schema ? schema.validator[item] : this.schema.validator[item])) {
                    errors.push(this.getErrorTextByValidator(item, value, schema));
                }
            });
        }
        return errors;
    }

    getValidatorFirstError(validators: Array<ValidatorType>, value: any): string | null {
        return this.getValidatorErrors(validators, value)[0] || null;
    }

    fieldValidator(value: any, validatorsList: Array<ValidatorType>): () => string | null {
        const validators = validatorsList;

        return () => {
            const error = this.getValidatorFirstError(validators, value);
            if (error) {
                return error;
            }
            return null;
        };
    }

    customValidator(value: any, validators: any): () => string | null {
        return this.fieldValidator(value, validators);
    }

    addCurrentInputToValidateArray(fieldName: string): void {
        if (!this.validationActiveArray.includes(fieldName)) {
            this.validationActiveArray.push(fieldName);
            this.activateValidation();
        }
    }

    fieldHasError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            (
                this.serverErrors[fieldName] ||
                this.validation.hasError(`${fieldName}`) ||
                Boolean(this.validation.firstError(`${fieldName}`))
            )
        );
    }

    fieldHasNotError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            !(this.serverErrors[fieldName] || this.validation.hasError(`${fieldName}`))
        );
    }

    getFieldErrorText(fieldName: string): string | null {
        if (!this.validationActiveArray.includes(fieldName)) {
            return null;
        }
        return this.serverErrors[fieldName] || this.validation.firstError(`${fieldName}`);
    }

    clearServerErrorsBase(fieldName: string): void {
        this.serverErrors[fieldName] = null;
    }

    noNumbersAndCharactersMethod($event: KeyboardEvent) {
        const a = /^[~`!@#$%\^&*()+=_\[\]\\';.,/{}|\\":<>\?]+$/i;
        if ((Number.isInteger(parseInt($event.key, 10)) &&
            $event.key !== '-') || a.test($event.key)) {
            $event.preventDefault();
        }
    }

    prepareServerFormErrors(data: ServerBaseDataOnError): serverErrors {
        if (!data.errors) {
            return {};
        }
        function getFieldErrorNameFromString(errorType: string): string | null {
            const subStringArray = errorType.split('.', 2);
            const index: number = subStringArray.indexOf('user');
            if (index !== -1) subStringArray.splice(index, 1);
            return subStringArray && subStringArray.length ? subStringArray[0] : null;
        }
        const errors: serverErrors = {};
        for (let i = 0; i < data.errors.length; i++) {
            const field = getFieldErrorNameFromString(data.errors[i].type);
            if (field) {
                errors[field] = data.errors[i].text;
            }
        }
        return errors;
    }
    // @ts-ignore-next-line
    emailValidator({ value, notRequired, requiredHint = i18n.t('VALIDATION.FIELD.REQUIRED') }: { value: string, notRequired?: boolean, requiredHint?: string }): boolean {
        return notRequired ? Validator.value(value).email() : Validator.value(value).required(requiredHint).email();
    }
    // @ts-ignore-next-line
    // eslint-disable-next-line max-len
    baseInputValidator({ value, minLength = 2, maxLength = 100, isRequired = true, requiredHint = i18n.t('VALIDATION.FIELD.REQUIRED') }: { value: string, minLength?: number, maxLength?: number, isRequired?: boolean, requiredHint?: string }): boolean {
        if (isRequired) {
            return Validator.value(value).required(requiredHint).minLength(minLength).maxLength(maxLength);
        }
        return Validator.value(value).minLength(minLength).maxLength(maxLength);
    }

    // @ts-ignore-next-line
    baseTextAreaValidator({ value, minLength = 2 }: { value: string, minLength?: number }): boolean {
        return Validator.value(value).required().minLength(minLength).maxLength(500);
    }

    requiredValidator({ value }: { value: string }): boolean {
        return Validator.value(value).required();
    }

    passwordValidator({ value }: { value: string }): boolean {
        return Validator.value(value).required().minLength(6, i18n.tc(`VALIDATION.FIELD.PASSWORD.MIN`));
    }

    get isValidationActivated(): boolean {
        return this.validation && this.validation.activated;
    }

    get isFormValid(): boolean {
        if (this.validation && this.validation.errors.length !== 0) {
            return false;
        }

        if (this.requiredFields) {
            for (let i = 0; i < this.requiredFields.length; i++) {
                const key = this.requiredFields[i];
                const passedRecord = this.validation.passedRecords.find((item: { field: string, value: boolean }) => item.field === key);

                if (!passedRecord || passedRecord.value === false) {
                    return false;
                }
            }

            return true;
        }

        return !!(this.serverErrors && this.validation && this.validation.passedRecords.length === Object.keys(this.serverErrors).length);
    }

    propsKeyDownMethodOnlyNumbers($event: KeyboardEvent, isPhonePrefixExist: boolean) {
        const availableKeys: Array<string> = [
            `Tab`,
            `Backspace`,
            `ArrowRight`,
            `ArrowLeft`,
        ];
        const currentInputValue = (<HTMLInputElement>$event.target).value;

        if (!Number.isInteger(parseInt($event.key, 10)) &&
            !availableKeys.includes($event.key) &&
            !($event.keyCode === 187 && $event.shiftKey) &&
            !($event.ctrlKey && $event.key === 'v' || $event.key === 'c') &&
            !($event.metaKey && $event.key === 'v' || $event.key === 'c') ||
            (isPhonePrefixExist && parseInt($event.key, 10) === 0 && !currentInputValue.length)) {
            $event.preventDefault();
        }
    }

    isValid(value: any, validator: ValidatorType, validatorValue: any): boolean {
        return isValid(value, validator, validatorValue);
    }
};
