<template>
<div class='b-select-location-page'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='h-text-center h-pt-30'>
            <h1>
                {{ $t('SELECT.LOCATION.APPOINTMENT.TITLE') }}
            </h1>
        </div>
        <div class='h-flex-center'>
            <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                <FwSelect
                    v-model='value'
                    v-focus='categoryLocationSkillNames.length && !isOneSkill'
                    class='fw-select-white qa-select-appointment-skill'
                    :clear-on-select='true'
                    :propsPlaceholder='$t(`SELECT.LOCATION.APPOINTMENT.PLACEHOLDER`)'
                    type='white'
                    :pagination='true'
                    :searchIcon='false'
                    :options='categoryLocationSkillNames'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                    :label='labelKey'
                    @input='onInput'>
                </FwSelect>
            </div>
        </div>
    </template>
    <portal v-if='isOneSkill' to='appointment-bar-buttons'>
        <FwButton
            class='qa-appointment-set-post-code'
            size='little'
            :disabled='!value'
            borderRadiusType='small-border'
            @click.native='onInput(value)'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';

import { CategoryLocationSkillNameType, AppointmentCategoryType } from '@/types/Appointment';
import { ParsedAddressData } from '@/types/GoogleMap';
import { ClientType } from '@/types/User';

import TranslateMixin from '@/mixins/TranslateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';

import {
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_BY_ON_SITE,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID, IN_SERVICE_POINT,
} from '@/helpers/appointment';

const AppointmentStore = namespace('AppointmentStore');

@Component
export default class AppointmentSelectLocationPage extends Mixins(TranslateMixin, NoDataMixin, AppointmentMixin) {
    @AppointmentStore.Mutation('setLocationName') setLocationName!: (locationName: CategoryLocationSkillNameType) => void;
    @AppointmentStore.Mutation('setAppointmentType') setAppointmentType!: (type: string) => void;
    @AppointmentStore.Mutation('setPlaceParsedData') setPlaceParsedData!: (address: ParsedAddressData | null) => void;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;

    value: CategoryLocationSkillNameType | null = null;
    loading: boolean = false;
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];

    routeMapping = {
        [ON_SITE_ID]: '/appointment/address/on-site',
        [BY_PHONE_ID]: '/appointment/postcode',
        [IN_BRANCH_BY_ONLINE_VIDEO]: '/appointment/postcode',
        [IN_BRANCH_BY_ON_SITE]: '/appointment/address/in-branch-on-site',
        [IN_BRANCH_VIDEO_ID]: '/appointment/choose-branch',
        [IN_BRANCH_PUBLIC_ID]: '/appointment/choose-branch',
        [IN_SERVICE_POINT]: '/appointment/choose-branch',
        [IN_BRANCH_PRIVATE_VIDEO_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_PHONE_PUBLIC_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_PHONE_PRIVATE_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_VIDEO_PRIVATE_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_VIDEO_PUBLIC_ID]: '/appointment/choose-branch',
    }

    routeMappingClient = {
        [ON_SITE_ID]: '/appointment/address/client/on-site',
        [IN_BRANCH_BY_ON_SITE]: '/appointment/address/client/in-branch-on-site',
        [BY_PHONE_ID]: '/appointment/client/postcode',
        [IN_BRANCH_BY_ONLINE_VIDEO]: '/appointment/client/postcode',
    }

    categoriesMappingArr = [
        'location_category_in_branch_public',
        'location_category_in_branch_private',
        'location_category_on_site_in_branch',
        'location_category_by_online_video',
        'location_category_in_branch_by_video_public',
        'location_category_in_branch_by_video_private',
        'location_category_by_phone',
        'location_category_in_branch_by_phone_public',
        'location_category_in_branch_by_phone_private',
        'location_category_on_site',
        'location_category_in_service_point',
        'location_category_in_branch_client_video_private',
    ];

    get isOneSkill() {
        return this.categoryLocationSkillNames.length === 1;
    }

    onInput(skill: CategoryLocationSkillNameType) {
        if (this.value && (!this.isOneSkill || skill)) {
            this.setLocationName((this.value as CategoryLocationSkillNameType));
            this.setAppointmentType(this.value.searchable_name);
            if (this.client && this.client.address && (this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow || this.isOnSiteInBranchFlow)) {
                this.$router.push({ path: this.routeMappingClient[this.value.searchable_name] });
            } else {
                this.$router.push({ path: this.routeMapping[this.value.searchable_name] });
            }
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.onInput(this.value as CategoryLocationSkillNameType);
        }
    }

    async created() {
        if (this.appointmentType) {
            this.$router.push({ path: '/appointment/select-type' });
        }
        document.addEventListener('keyup', this.handleKeyUp);
        if (this.client && this.placeParsedData) {
            // case if user go back after choose address
            this.setPlaceParsedData(null);
        }
        if (!this.appointmentCategory) {
            this.$_NoDataMixin_bookFlowNoData();
        } else {
            this.loading = true;
            const data = await OnboardingApi.categoryLocationSkillNamesForAppointment(this.appointmentCategory.id);
            const { locations_skills_for_appointment_category } = data;

            this.categoryLocationSkillNames = [];

            this.categoriesMappingArr.forEach(item => {
                locations_skills_for_appointment_category.forEach(item1 => {
                    if (item1.searchable_name === item) {
                        this.categoryLocationSkillNames.push(item1);
                    }
                });
            });

            if (this.isOneSkill) {
                this.value = this.categoryLocationSkillNames[0];
                this.onInput(this.value);
            }

            this.loading = false;
        }
    }

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    }
}
</script>

<style lang='sass'>
.fw-select-address .multiselect .fw-select__caret
    opacity: 0

.b-select-location-page

    .fw-select-white
        box-shadow: none!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important

    @include media('<=phone')
        padding: 0 15px
</style>
